import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
// import Projects from "../components/Projects";
// import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Technologies from "../components/Technologies";
import OurTeam from "../components/OurTeam";
import Clients from "../components/clients";
import { FaWhatsapp } from "react-icons/fa";
const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;


const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <OurTeam />
      {/* <Projects /> */}
      <Technologies />
      {/* <Blog /> */}
      <Clients />
      <Contact />
      {/* WhatsApp Icon */}
      <a
        href={`https://wa.me/${whatsappNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-700 p-4 rounded-full text-white shadow-lg hover:bg-green-600 transition-colors duration-300"
      >
        <FaWhatsapp size={30} />
      </a>
    </>
  );
};

export default Home;
