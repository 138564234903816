import css from "../assets/technoloyImages/css.png";
import firebase from "../assets/technoloyImages/firebase.png";
import html from "../assets/technoloyImages/html.png";
import react from "../assets/technoloyImages/react.png";
import javascript from "../assets/technoloyImages/javascript.png";
import mui from "../assets/technoloyImages/mui.png";
import nodejs from "../assets/technoloyImages/nodejs.png";
import python from "../assets/technoloyImages/python.png";
import java from "../assets/technoloyImages/java.png";
import typescript from "../assets/technoloyImages/typescript.png";
import flutter from "../assets/technoloyImages/flutter.png";
import aws from "../assets/technoloyImages/aws.png";
import android from "../assets/technoloyImages/android.png";
import cicd from "../assets/technoloyImages/cicd.png";
import docker from "../assets/technoloyImages/docker.png";
import swift from "../assets/technoloyImages/swift.png";
import tailwind from "../assets/technoloyImages/tailwind.png";
import Kubernetes from "../assets/technoloyImages/Kubernetes.png";
import django from "../assets/technoloyImages/django.png";

export const NBFMainTasks = [
  {
    title: "Order Management",
    description:
      "Admin can create and manage orders, send them to the factory for production.",
    color: "bg-sky-300",
  },
  {
    title: "Payment Methods",
    description:
      "Customers can pay using EasyPaisa, cash, check, or other payment modes.",
    color: "bg-green-300",
  },
  {
    title: "Account Management",
    description: "Admin can track total purchases, today's sales, and profit.",
    color: "bg-sky-200/100",
  },
  {
    title: "Inventory System",
    description: "Complete inventory management system to track bakery items.",
    color: "bg-green-200/100",
  },
];

export const technologiesUsedInNBF = [
  { title: "HTML5", logo: html },
  { title: "React-JS", logo: react },
  { title: "JavaScript", logo: javascript },
  { title: "NodeJS", logo: nodejs },
  { title: "Firebase", logo: firebase },
  { title: "Material UI", logo: mui },
];

export const TugoMainTasks = [
  {
    title: "Effortless Event Registration",
    description:
      "Easily register for upcoming events with just a few taps. Our intuitive interface ensures a smooth and user-friendly registration process",
    color: "bg-sky-300",
  },
  {
    title: "Organizer Dashboard",
    description:
      "Empower organizers with a feature-rich dashboard to manage events, track registrations, and communicate with participants. Streamline event logistics effortlessly",
    color: "bg-green-300",
  },
  {
    title: "Real-time Notifications",
    description:
      "Stay informed with real-time notifications for event updates, reminders, and important announcements. Never miss out on a crucial detail again",
    color: "bg-sky-200/100",
  },
  {
    title: "User Profiles",
    description:
      "Personalize your experience with user profiles. Participants can manage their event history, preferences, and stay connected with the community",
    color: "bg-green-200/100",
  },
  {
    title: "Location Selection with Google Maps",
    description:
      "Dynamically select event locations using Google Maps integration. Ensure accuracy and provide participants with easy-to-follow directions",
    color: "bg-sky-100/100",
  },
  {
    title: "Local Storage Image Selection",
    description:
      "Personalize your event by choosing images from your device's local storage or can capture using their Camera",
    color: "bg-green-100/100",
  },
];

export const technologiesUsedInTugo = [
  { title: "JavaScript", logo: javascript },
  { title: "React-Native", logo: react },
  { title: "TypeScript", logo: typescript },
  { title: "Python", logo: python },
  { title: "Firebase", logo: firebase },
  { title: "Django", logo: django },
];

export const clientsData = [
  {
    id: 1,
    image: "https://randomuser.me/api/portraits/men/1.jpg", // Sample image link
    name: "Muhammad Rasheed", 
    comment: "InnuNext Technologies delivered amazing results for our project.",
    rating: 5,
  },
  {
    id: 2,
    image: "https://randomuser.me/api/portraits/women/2.jpg", // Sample image link
    name: "Sophie Williams", // UK name
    comment: "Highly professional team! Very satisfied with the outcome.",
    rating: 4,
  },
  {
    id: 3,
    image: "https://randomuser.me/api/portraits/men/3.jpg", // Sample image link
    name: "Liam Anderson", // Canadian name
    comment: "Great communication and excellent support throughout the process.",
    rating: 5,
  },
  {
    id: 4,
    image: "https://randomuser.me/api/portraits/women/4.jpg", // Sample image link
    name: "Olivia Thompson", // UK name
    comment: "Outstanding experience from start to finish!",
    rating: 5,
  },
  {
    id: 5,
    image: "https://randomuser.me/api/portraits/men/5.jpg", // Sample image link
    name: "Ethan Roberts", // Canadian name
    comment: "The team is highly skilled and professional.",
    rating: 4,
  },
];


export const GPSMainTasks = [
  {
    title: "Track Real Time Location",
    description:
      "The app ensures persistent location tracking, even when it's closed, providing a comprehensive view of users' movements",
    color: "bg-sky-300",
  },
  {
    title: "Data Precision",
    description:
      "Coordinates are captured at regular intervals (every 30 seconds) and securely stored in Firebase, ensuring accurate and up-to-date location information",
    color: "bg-green-300",
  },
  {
    title: "Google Maps Integration",
    description:
      "The application leverages Google Maps to enhance the user experience, providing an interactive and detailed representation of the tracked routes",
    color: "bg-sky-200/100",
  },
  {
    title: "User-Friendly Interface",
    description:
      "The collected data is intelligently visualized, allowing users to review their travel history, routes taken, and the duration spent at different locations",
    color: "bg-green-200/100",
  },
];

export const technologiesUsedInGPS = [
  { title: "JavaScript", logo: javascript },
  { title: "React-native", logo: react },
  { title: "TypeScript", logo: typescript },
  { title: "Googl Map", logo: nodejs },
  { title: "Firebase", logo: firebase },
];

export const FSOMainTasks = [
  {
    title: "Distributor App",
    description:
      "Our team developed a cutting-edge distribution app for Android and iOS using React Native and Firebase, powered by JavaScript. This dynamic application offers a comprehensive set of features designed to streamline and enhance the distribution process. It is tailored to the specific needs of your business, enabling efficient management and monitoring of field sales operations. Access to real-time data and insights, including field sales agents' locations and performance metrics. Can track the number of outlets visited and submitted reports.",
    color: "bg-blue-100",
  },
  {
    title: "Project Overview",
    description:
      "Our team developed a cutting-edge distribution app for Android and iOS using React Native and Firebase, powered by JavaScript. This dynamic application offers a comprehensive set of features designed to streamline and enhance the distribution process. It is tailored to the specific needs of your business, enabling efficient management and monitoring of field sales operations. Access to real-time data and insights, including field sales agents' locations and performance metrics. Can track the number of outlets visited and submitted reports.",
    color: "bg-green-100",
  },
  {
    title: "Role-based Access Control",
    description:
      "Provides oversight of all field sales agents, ensuring complete visibility across the team.",
    color: "bg-indigo-100",
  },
  {
    title: "Head",
    description:
      "Provides oversight of all field sales agents, ensuring complete visibility across the team.",
    color: "bg-pink-100",
  },
  {
    title: "Manager",
    description:
      "Access to real-time data and insights, including field sales agents' locations and performance metrics. Can track the number of outlets visited and submitted reports.",
    color: "bg-yellow-100",
  },
  {
    title: "fso",
    description:
      "FSOs can efficiently survey and list product details using the app. They have the ability to capture live images of available products in retailer shops or outlets, ensuring a visual record of inventory.",
    color: "bg-red-100",
  },
];

export const technologiesUsedInFSO = [
  { title: "HTML5", logo: html },
  { title: "React-Native", logo: react },
  { title: "JavaScript", logo: javascript },
  { title: "NodeJS", logo: nodejs },
  { title: "Firebase", logo: firebase },
  { title: "Material UI", logo: mui },
];

export const keywords = {
  companyName: "InnuNext Technologies",
  about: "About",
  projects: "Projects",
  blog: "Blog",
  contact: "Contact",
  resume: "Resume",
  seeProjects: "See Projects",
  ourTeam: "Our Team",
  quoteMessage:
    '"Coming together is a beginning, staying together is progress, and working together is success." – Henry Ford',

  description:
    " where innovation meets technology! We're a dynamic software house dedicated to crafting exceptional web and mobile applications that empower businesses and individuals to thrive in the digital era. With a passion for cutting-edge solutions and a team of experienced developers, we're here to transform your ideas into interactive, user-friendly, and feature-rich software products. Explore the endless possibilities with InnuNext Technologies and let's embark on a journey of digital transformation together.",
  aboutUs: "About Us",
  aboutUsPara1:
    "At InnuNext Technologies, we are not just another software development company; we are your partners in innovation. Our journey began with a vision to revolutionize the digital landscape, and today, we stand as a leading force in the world of web and mobile app development.",
  aboutUsPara2:
    "What sets us apart is our unwavering commitment to excellence, a passion for technology, and a relentless pursuit of perfection. With a dedicated team of creative minds and technical experts, we've successfully delivered tailored solutions to clients across various industries, consistently exceeding their expectations.",
  aboutUsPara3:
    "InnuNext Technologies is more than a software development company; we are the architects of digital transformation. Whether you're a startup looking to make your mark or an established business seeking a digital upgrade, we're here to make your vision a reality. Join us on this exciting journey, and together, we'll redefine what's possible in the world of web and mobile app development. Discover the InnuNext Technologies difference, and let's shape the future together",
  blogs: "Blogs",
  someOfOurBestBlogs: "Some of our best blogs.",
  readMore: "Read More",
  contactUs: "Contact Us",
  contacUsMessage:
    "Ready to turn your digital dreams into reality? Reach out to our expert team of software developers today, and let's embark on a journey to create cutting-edge solutions tailored to your needs.",
  email: "Email",
  whatsapp: "Whatsapp",
  allRightReserved: "InnuNext Technologies all right reserved",
  welcomeTo: "Welcome to ",
  viewBio: "View Bio",
  projects: "Projects",
  projectDescription:
    " These are some of our best projects. We have built these with React, React-Native, Firebase, Pyhton Djongo, NodeJS and vanilla CSS. Check them out. ",
  moreDetails: "More Details",
  videoDemo: "Video Demo",
  ourSkillsAndTechnologies: "Our Skills & Technologies",
  clients: "Clients",
  ourClients: "Our Clients",
  ourClientMessage: "Here’s what our clients have to say about us.",
  sendUsMessage: "Send us a Message",
  name: "Name",
  email: "Email",
  message: "Message",
  sendMessage: "Send Message",
};
