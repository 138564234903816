import { clientsData, keywords } from "../Constants/ProjectData";
import React, { useState, useEffect } from "react";
import "../App.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Clients() {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [animationState, setAnimationState] = useState("");

  const handleNext = () => {
    setAnimationState("animate-next");
  };

  const handlePrevious = () => {
    setAnimationState("animate-prev");
  };

  // Get the three visible clients
  const getVisibleClients = () => {
    const prevIndex =
      currentIndex === 0 ? clientsData.length - 1 : currentIndex - 1;
    const nextIndex =
      currentIndex === clientsData.length - 1 ? 0 : currentIndex + 1;

    return [
      clientsData[prevIndex], // Left Visible Card
      clientsData[currentIndex], // Center Visible Card
      clientsData[nextIndex], // Right Visible Card
    ];
  };

  const visibleClients = getVisibleClients();

  useEffect(() => {
    if (animationState) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          animationState === "animate-next"
            ? prevIndex === clientsData.length - 1
              ? 0
              : prevIndex + 1
            : prevIndex === 0
            ? clientsData.length - 1
            : prevIndex - 1
        );
        setAnimationState(""); // Reset animation state after transition
      }, 500); // Match the animation duration

      return () => clearTimeout(timer);
    }
  }, [animationState]);

  return (
    <section className="py-20 bg-primary relative" id="clients">
      <div className="container mx-auto px-6 md:px-12 lg:px-15">
        <div className="mb-12 space-y-4 text-center">
          <h2 className="text-4xl font-bold text-white md:text-5xl">
            {keywords.ourClients}
          </h2>
          <p className="text-white lg:w-8/12 lg:mx-auto">
            {keywords.ourClientMessage}
          </p>
        </div>

        {/* Left Scroll Button */}
        <button
          onClick={handlePrevious}
          className="absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500 bg-primary rounded-full p-3 hover:text-white focus:outline-none z-10"
        >
          <FaChevronLeft size={24} />
        </button>

        <div className="flex justify-center items-center py-12 space-x-8">
          {visibleClients.map((client, index) => (
            <div
              key={client.id}
              className={`transition-transform duration-500 ease-in-out transform flex-none ${
                index === 1 ? "scale-110 z-20" : "scale-90 opacity-60"
              } w-60 bg-secondery rounded-lg shadow-lg p-6 text-center text-white`}
            >
              <div className="w-24 h-24 mx-auto rounded-full overflow-hidden mb-2">
                <img
                  src={client.image}
                  alt={client.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-semibold">{client.name}</h3>
              <p className="italic">"{client.comment}"</p>
              <div className="text-yellow-500 mt-4">
                {"⭐".repeat(client.rating)}{" "}
              </div>
            </div>
          ))}
        </div>

        {/* Right Scroll Button */}
        <button
          onClick={handleNext}
          className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-500 bg-primary hover:text-white rounded-full p-3 focus:outline-none z-10"
        >
          <FaChevronRight size={24} />
        </button>
      </div>
    </section>
  );
}
